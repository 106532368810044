<template>
    <div>
        <!-- 顶部 -->
        <el-row>
            <el-col :span='24'>
                <el-form :inline='true'>
                    <el-form-item label='关键字：'>
                        <el-input v-model='filters.keyWord' @input='() => filters.keyWord = filters.keyWord.replace(/\s/g,"")' placeholder='发票抬头/姓名/手机号' clearable @keyup.enter.native='getLearningRecordSummaryListPage'></el-input>
                    </el-form-item>
                    <el-form-item label="发票类型:">
                    <el-select v-model="filters.invoiceType" clearable>
                        <el-option
                            v-for="item in invoiceTypeArr"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开票状态:">
                    <el-select v-model="filters.kpStatus" clearable>
                        <el-option
                            v-for="item in kpStatusArr"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
            border
            v-loading='listLoading'
            :data="tableData"
            :row-style='{height:"38px"}'
            highlight-current-row
            style="width: 100%"
        >
            <el-table-column type="index" width="55" label='序号' align='center'></el-table-column>
            <!-- <el-table-column prop='InvoiceContent' width="" label='发票内容' align='center' show-overflow-tooltip></el-table-column> -->
            <el-table-column prop='SJname' width="150" label='收件人姓名' align='center'></el-table-column>
            <el-table-column prop='SJphone' width="150" label='收件人电话' align='center'></el-table-column>
            <el-table-column prop='SJemail' width="200" label='收件人email' align='center'></el-table-column>
            <el-table-column prop='InvoiceType' width="" label='发票类型' align='center'>
                <template scope="scope">
                    <el-tag v-if="scope.row.InvoiceType == 4 || scope.row.InvoiceType == 61" type="">电子专票</el-tag>
                    <el-tag v-else-if="scope.row.InvoiceType == 51 || scope.row.InvoiceType == 62" type="success">电子普票</el-tag>
                    <el-tag v-else type="danger">-</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop='IssuerType' width="" label='企业类型' align='center'>
                <template scope="scope">
                    <el-tag v-if="scope.row.IssuerType == 0" type="">个人</el-tag>
                    <el-tag v-else-if="scope.row.IssuerType == 1" type="success">企业</el-tag>
                    <el-tag v-else type="danger">-</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop='InvoiceName' width="" label='发票抬头' align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop='InvoiceMoney' width="" label='开票金额' align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop='CreateTime' width="110" label='申请时间' :formatter="formatCreateTime1" align='center'></el-table-column>
            <el-table-column prop='OpenTime' width="110" label='开票时间' :formatter="formatCreateTime" align='center'></el-table-column>
            <el-table-column prop='KPStatus' width="" label='开票状态' align='center' show-overflow-tooltip>
                <template scope="scope">
                    <!-- <el-tag v-if="scope.row.KPStatus == 0" type="">未申请</el-tag> -->
                    <el-tag v-if="scope.row.KPStatus == 255" type="">申请中</el-tag>
                    <el-tag v-else-if="scope.row.KPStatus == 1" type="">开具中</el-tag>
                    <el-tag v-else-if="scope.row.KPStatus == 2" type="success">已开具</el-tag>
                    <el-tag v-else-if="scope.row.KPStatus == 4" type="danger">已红冲</el-tag>
                    <el-tag v-else type="">未知</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="100"
                align='center'
            >
                <template slot-scope="scope">
                    <el-button v-if="scope.row.KPStatus == 2" @click="handleDown(scope.row)" type="text">下载发票</el-button>
                    <el-button v-else type="text">-</el-button>
                </template>
            </el-table-column>
        </el-table>  
        <!-- 分页 -->
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pages.pageIndex"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pages.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pages.dataCount"
        >
        </el-pagination>      
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import util from "../../../util/date";
import {getStudentInvoiceAllPageList} from '@/api/api'
import * as XLSX from 'xlsx'
export default {
    components: { Toolbar },
    data(){
        return {
            buttonList: [],
            filters:{
                keyWord:"",
                invoiceType:"",
                kpStatus:"",
            },
            invoiceTypeArr: [
                {value: 4,name:"电子专票"},
                {value: 51,name:"电子普票"},
            ],
            kpStatusArr: [
                // {value: 0,name:"未申请"},
                {value: 255,name:"申请中"},
                {value: 1,name:"开具中"},
                {value: 2,name:"已开具"},
                {value: 4,name:"已红冲"},
            ],
            // 列表
            listLoading:false, // 列表加载
            tableData:[],
            pages:{
                pageIndex:1,
                pageSize:20,
                dataCount:0
            },
	        exportList:[],
	        isExporting:false,
	        exportName:[
		        {header:'收件人姓名',property:'SJname'},
		        {header: '收件人电话',property: 'SJphone'},
		        {header: '收件人email',property: 'SJemail'},
		        {header: '发票类型（4、61：电子专票，51、62：电子普票）',property: 'InvoiceType'},
		        {header: '企业类型（0：个人，1：企业）',property: 'IssuerType'},
		        {header: '发票抬头',property: 'InvoiceName'},
		        {header: '开票金额',property: 'InvoiceMoney'},
		        {header: '申请时间',property: 'CreateTime'},
		        {header: '开票时间',property: 'OpenTime'},
		        {header: '开票状态（255：申请中，1：开具中，2：已开具，4：已红冲）',property: 'KPStatus'},
	        ],
        }
    },
    methods:{
	    //导出汇总
	    async exportAll(){
		    for (let i = 1; i < ((this.pages.dataCount / this.pages.pageSize) < 1 ? 2 : +Math.ceil(this.pages.dataCount / this.pages.pageSize)) + 1; i++) {
			    var params = {
				    pageIndex:i,
				    pageSize: this.pages.pageSize,
				    keyWord:this.filters.keyWord,
				    invoiceType:this.filters.invoiceType,
				    kPStatus:this.filters.kpStatus,
			    }
			    const res = await getStudentInvoiceAllPageList(params)
			    let result = res.data
			    if (result.Success) {
				    this.exportList.push(...result.Response.Data)
			    }
			    setTimeout(()=>{
				   
			    },100)
		    }
		    console.log(this.exportList,'exportList')
	    },
	    exportToExcel (dataArray, headersMapping) {
		    // 从映射中提取自定义表头
		    const headers = headersMapping.map(mapping => mapping.header);
		    
		    // 从数据中筛选并映射对应属性
		    const filteredData = dataArray.map(obj =>
				    headersMapping.reduce((acc, mapping) => {
					    const { header, property } = mapping;
					    if (Object.prototype.hasOwnProperty.call(obj, property)) {
						    acc[header] = obj[property];
					    }
					    return acc;
				    }, {})
		    );
		    
		    const workbook = XLSX.utils.book_new();
		    const worksheet = XLSX.utils.json_to_sheet(filteredData, { header: headers });
		    
		    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
		    
		    const excelBuffer = XLSX.write(workbook, {
			    bookType: 'xlsx',
			    type: 'array',
		    });
		    
		    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		    const fileName = '发票.xlsx';
		    
		    if (window.navigator.msSaveOrOpenBlob) {
			    // For IE/Edge browsers
			    window.navigator.msSaveOrOpenBlob(blob, fileName);
		    } else {
			    // For other browsers
			    const link = document.createElement('a');
			    link.href = window.URL.createObjectURL(blob);
			    link.download = fileName;
			    document.body.appendChild(link);
			    link.click();
			    document.body.removeChild(link);
		    }
	    },
	    //导出
	    async exportListData () {
		    
		    if (this.isExporting) {
			    console.log('导出操作仍在进行中...');
			    this.$message.error('导出操作仍在进行中...')
			    return; // 如果导出仍在进行中，则退出函数
		    }
		    this.isExporting = true; //（isExporting是一个用于控制按钮状态的变量）
		    await this.exportAll()
		    setTimeout(()=>{
			    this.exportToExcel(this.exportList,this.exportName)
			    this.isExporting = false;
			    this.exportList = []
		    },0)
		    
	    },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 查询
        GetInvoiceList(){
            this.pages.pageIndex = 1
            this.getData()
        },
        // 获取列表数据
        getData(){
            var params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
                keyWord:this.filters.keyWord,
                invoiceType:this.filters.invoiceType,
                kPStatus:this.filters.kpStatus,
            }
            this.listLoading = true
            getStudentInvoiceAllPageList(params).then(res => {
                this.listLoading = false
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() => {
                this.listLoading = false
                this.$message.error(res.data.Message)
            })
        },
        // 下载发票
        handleDown(row) {
            if(row.KPStatus == 2) {
                if(row.PDFAddress) {
                    window.open(row.PDFAddress)                
                } else {
                    this.$message.warning("发票下载失败！")
                }
            } else {
                this.$message.warning("发票不可下载！")
            }
        },
        // 分页相关操作
        handleSizeChange(value){
            this.pages.pageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            this.pages.pageIndex = value
            this.getData()
        },
        formatCreateTime: function(row, column) {
            if(row.KPStatus == 2) {
                return !row.OpenTime || row.OpenTime == ""
                ? ""
                : util.formatDate.format(new Date(row.OpenTime), "yyyy-MM-dd");
            } else {
                return '-'
            }
        },
        formatCreateTime1: function(row, column) {
            // if(row.KPStatus == 2) {
                return !row.CreateTime || row.CreateTime == ""
                ? ""
                : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
            // } else {
            //     return '-'
            // }
        },
    },
    created(){
        this.getData()
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style scoped>
    .el-scrollbar__wrap {
        overflow-x: hidden;
    }
</style>
<style>
    .el-tooltip__popper {
        max-width: 400px;
    }
</style>